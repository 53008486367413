
























































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

class PageFinancialStatementRequest {
}

@Component({
    components: { VWidget },
})

export default class FinancialStatementForm extends ListBase {
    pagerequest: PageFinancialStatementRequest = new PageFinancialStatementRequest();
    @Prop({ type: Number }) year: number;
    @Prop({ type: Number }) customer: number;
    @Prop({ type: Boolean, default: true }) editable: boolean;
    type: number = Util.abp.list.get('T_STATEMENT', 'G').id;
    //totals: any = {};

    /***** computed methods *****/

    get loading() {
        return this.$store.state.financialstatement.loading;
    }

    get list() {
        return this.$store.state.financialstatement.list.filter(item => { return item.type_id == this.type });
    }

    get netSale() {
        let year = (this.year != null ? this.year : parseInt(new Date().toISOString().substr(0, 4)));
        let netSale = {};
        for (var i = year - 2; i <= year; i++) {
            netSale[i] = (this.list[0]['amount_' + i] != undefined ? this.list[0]['amount_' + i] : 0);
        }
        return netSale;
    }

    get periods() {
        let year = (this.year != null ? this.year : parseInt(new Date().toISOString().substr(0, 4)));
        let result = [];
        for (var i = year - 2; i <= year; i++)
            result.push({ id: i, name: 'DIC ' + String(i) });
        return result;
    }

    get currency_sign() {
        return Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
    }

    get columns() {
        let cols: Array<any> = [{ value: 'concept_name', text: 'Balance General', type: 'text', sortable: false, align: 'start', width: '280px', class: '' }];

        this.periods.forEach(m => {
            cols.push({ value: 'amount_' + m.id, text: 'Monto', type: 'text', width: '82px', sortable: false, align: 'center' });
            cols.push({ value: 'percent_' + m.id, text: '%', type: 'text', width: '82px', sortable: false, align: 'center' });
        });

        return cols;
    }

    get totals() {
        let totals: any = {};
        this.list.forEach(item => {
            this.periods.forEach(m => {
                if (item['amount_' + m.id] != undefined && item['amount_' + m.id] != null && item['amount_' + m.id] != '') {
                    if (totals['amount_' + m.id] == undefined) totals['amount_' + m.id] = 0;
                    totals['amount_' + m.id] += parseFloat(item['amount_' + m.id]);
                }
            });
        });
        return totals;
    }

    get groupTotals() {
        let totals: any = {};
        this.list.forEach(item => {
            this.periods.forEach(m => {
                if (totals[item.group_id + '_' + m.id] == undefined) totals[item.group_id + '_' + m.id] = 0;
                if (item['amount_' + m.id] != undefined && item['amount_' + m.id] != null && item['amount_' + m.id] != '') {
                    totals[item.group_id + '_' + m.id] += parseFloat(item['amount_' + m.id]);
                }
            });
        });
        return totals;
    }

    get ventaTotal() {
        return Util.abp.list.get('G_GANANCIA', 'G1').id;
    }

    get utilidad() {
        return Util.abp.list.get('G_GANANCIA', 'G2').id;
    }

    get utilidadOpera() {
        return Util.abp.list.get('G_GANANCIA', 'G3').id;
    }

    get utilidadAntes() {
        return Util.abp.list.get('G_GANANCIA', 'G4').id;
    }

    get utilidadPerdi() {
        return Util.abp.list.get('G_GANANCIA', 'G5').id;
    }

    /***** fields events *****/

    getInitials(string) {
        return Util.getInitials(string);
    }

    onFocus(e) {
        e.target.select();
    }


    async saveAmount(item) {
        if (item != null) {
            await this.$store.dispatch({
                type: 'financialstatement/updateConcept',
                data: item
            });
        }
    }

    async mounted() {

    }

    async created() {
    }
}
