var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 striped mb-2",attrs:{"headers":_vm.columns,"items":_vm.list,"dense":"","loading":_vm.loading,"loading-text":"Cargando... Por favor Espere","group-by":"group_id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@5"}})]},proxy:true}:null,{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return undefined}},{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.periods),function(period){return _c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(_vm._s(period.name))])})],2)])]}},{key:"item",fn:function(props){return [_c('tr',[_c('td',{class:(props.item.number != null ? 'text-start' : 'text-end') + ' subtitle'},[(props.item.number != null)?_c('v-list-item',{staticStyle:{"min-height":"20px"}},[_c('v-list-item-content',{staticClass:"py-0"},[_vm._v(" "+_vm._s(props.item.concept_name)+" ")])],1):_c('b',[_vm._v(_vm._s(props.item.concept_name))])],1),_vm._l((_vm.periods),function(period){return [_c('td',{staticClass:"text-center px-2"},[(_vm.editable)?_c('v-text-field',{staticClass:"table-input",attrs:{"type":"number","flat":"","disabled":_vm.customer == null,"dense":"","hide-details":""},on:{"focus":_vm.onFocus},model:{value:(props.item['amount_' + period.id]),callback:function ($$v) {_vm.$set(props.item, 'amount_' + period.id, $$v)},expression:"props.item['amount_' + period.id]"}}):_c('span',[_vm._v(_vm._s(_vm.formatNumber(props.item['amount_' + period.id])))])],1),_c('td',[_vm._v(_vm._s(_vm.totals['amount_' + period.id] > 0 && props.item['amount_' + period.id] != null ? (props.item['amount_' + period.id] / _vm.totals['amount_' + period.id] * 100).toFixed(2) : 0)+" %")])]})],2)]}},{key:"group.summary",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{staticClass:"text-end font-weight-bold body-1  blue lighten-4"},[_vm._v(_vm._s(items[0].group_name))]),_vm._l((_vm.getSubtotal(items)),function(row){return [_c('td',{staticClass:"text-center px-2  font-weight-bold body-1 blue lighten-4"},[_vm._v(" "+_vm._s(_vm.formatNumber(Math.round(row)))+" ")]),_c('td',{staticClass:" blue lighten-4"})]})]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("event")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h3',[_vm._v("No hay registro disponibles!")])]),_c('span',{staticClass:"caption mb-4"},[_vm._v(" Parece que todavía no hay ningún registro "),_c('br'),_vm._v(" Por favor, inténtalo más tarde. Los registros aparecerán próximamente ")])],1)]},proxy:true}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"border-top"},[_c('td',{staticClass:"text-end font-weight-bold body-1 subtitle"},[_vm._v("TOTAL")]),_vm._l((_vm.periods),function(period){return [_c('td',{staticClass:"text-center px-2"},[_vm._v(" "+_vm._s(_vm.formatNumber(Math.round(_vm.totals['amount_' + period.id])))+" ")]),_c('td')]})],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }