













































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

class PageFinancialStatementRequest {
}

@Component({
    components: { VWidget },
})

export default class FinancialStatementForm extends ListBase {
    pagerequest: PageFinancialStatementRequest = new PageFinancialStatementRequest();
    @Prop({ type: Number }) year: number;
    @Prop({ type: Number }) customer: number;
    type: number = Util.abp.list.get('T_STATEMENT', 'G').id;
    //totals: any = {};

    /***** computed methods *****/

    get loading() {
        return this.$store.state.financialstatement.loading;
    }

    get list() {
        let result = [
            { group: '1. LIQUIDEZ', title: 'Capital de trabajo', decimals: 0 },
            { group: '1. LIQUIDEZ', title: 'Activo cte / pasivo cte (v)', decimals: 2 },
            { group: '2. SOLVENCIA', title: 'Grado de Popiedad', decimals: 0, suffix: ' %' },
            { group: '3. ENDEUDAMIENTO', title: 'Pasivo Patrimonio', decimals: 2 },
            { group: '3. ENDEUDAMIENTO', title: 'Deuda Financiera / EBITDA', decimals: 0 },
            { group: '4. RENTABILIDAD', title: 'EBITDA', decimals: 0 },
            { group: '4. RENTABILIDAD', title: 'ROE', decimals: 0, suffix: ' %' },
            { group: '4. RENTABILIDAD', title: 'ROA', decimals: 0, suffix: ' %'},
        ];

        //patrimonio
        this.periods.forEach(m => {
            result[0]['amount_' + m.id] = this.groupTotals[this.activoCorr + '_' + m.id] - this.groupTotals[this.pasivoCorr + '_' + m.id];
            result[1]['amount_' + m.id] = this.groupTotals[this.pasivoCorr + '_' + m.id] > 0 ? this.groupTotals[this.activoCorr + '_' + m.id] / this.groupTotals[this.pasivoCorr + '_' + m.id] : 0;
            result[2]['amount_' + m.id] = this.totals[this.pasivos + '_' + m.id] > 0 ? Math.round(this.groupTotals[this.patrimonio + '_' + m.id] / this.totals[this.pasivos + '_' + m.id] * 100) : 0;
            result[3]['amount_' + m.id] = this.groupTotals[this.patrimonio + '_' + m.id] > 0 ? (this.groupTotals[this.pasivoCorr + '_' + m.id] + this.groupTotals[this.pasivoNoCorr + '_' + m.id]) / this.groupTotals[this.patrimonio + '_' + m.id] : 0;
            result[4]['amount_' + m.id] = this.montoDeudaFina[m.id] / this.montoUtilOperativa[m.id];
            result[5]['amount_' + m.id] = this.montoUtilOperativa[m.id];
            result[6]['amount_' + m.id] = this.groupTotals[this.patrimonio + '_' + m.id] > 0 ? Math.round(this.montoUtilPerdida[m.id] / this.groupTotals[this.patrimonio + '_' + m.id] * 100) : 0;
            result[7]['amount_' + m.id] = this.totals[this.activos + '_' + m.id] > 0 ? Math.round(this.montoUtilOperativa[m.id] / this.totals[this.activos + '_' + m.id] * 100) : 0;

        });

        return result;
    }

    get montoDeudaFina() {
        let totals: any = {};
        this.$store.state.financialstatement.list.forEach(item => {
            this.periods.forEach(m => {
                if (item['amount_' + m.id] != undefined && item['amount_' + m.id] != null && item['amount_' + m.id] != '' && (item.number == 21 || item.number == 29 || item.number == 32)) {
                    if (totals[m.id] == undefined) totals[m.id] = 0;
                    totals[m.id] += parseFloat(item['amount_' + m.id]);
                }
            });
        });
        return totals;
    }

    get montoUtilOperativa() {
        let totals = {};
        this.periods.forEach(m => {
            totals[m.id] = this.groupTotals[this.utilidadOpera + '_' + m.id] + this.groupTotals[this.ventaTotal + '_' + m.id] + this.groupTotals[this.utilidad + '_' + m.id];
        });

        return totals;
    }

    get montoUtilPerdida() {
        let totals = {};
        this.periods.forEach(m => {
            totals[m.id] = this.montoUtilOperativa[m.id] + this.groupTotals[this.utilidadPerdi + '_' + m.id] + this.groupTotals[this.utilidadAntes + '_' + m.id];
        });

        return totals;
    }

    get periods() {
        let year = (this.year != null ? this.year : parseInt(new Date().toISOString().substr(0, 4)));
        let result = [];
        for (var i = year - 2; i <= year; i++)
            result.push({ id: i, name: 'DIC ' + String(i) });
        return result;
    }

    get currency_sign() {
        return Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
    }

    get columns() {
        let cols: Array<any> = [{ value: 'title', text: 'INDICADORES PRINCIPALES', type: 'text', sortable: false, align: 'start', width: '280px', class: '' }];

        this.periods.forEach(m => {
            cols.push({ value: 'amount_' + m.id, text: m.name, type: 'text', width: '82px', sortable: false, align: 'center' });
        });

        return cols;
    }

    get totals() {
        let totals: any = {};
        this.$store.state.financialstatement.list.forEach(item => {
            this.periods.forEach(m => {
                if (item['amount_' + m.id] != undefined && item['amount_' + m.id] != null && item['amount_' + m.id] != '') {
                    if (totals[item.type_id + '_' + m.id] == undefined) totals[item.type_id + '_' + m.id] = 0;
                    totals[item.type_id + '_' + m.id] += parseFloat(item['amount_' + m.id]);
                }
            });
        });
        return totals;
    }

    get groupTotals() {
        let totals: any = {};
        this.$store.state.financialstatement.list.forEach(item => {
            this.periods.forEach(m => {
                if (totals[item.group_id + '_' + m.id] == undefined) totals[item.group_id + '_' + m.id] = 0;
                if (item['amount_' + m.id] != undefined && item['amount_' + m.id] != null && item['amount_' + m.id] != '') {
                    totals[item.group_id + '_' + m.id] += parseFloat(item['amount_' + m.id]);
                }
            });
        });
        return totals;
    }

    get activos() {
        return Util.abp.list.get('T_STATEMENT', 'A').id;
    }

    get pasivos() {
        return Util.abp.list.get('T_STATEMENT', 'P').id;
    }

    get activoCorr() {
        return Util.abp.list.get('G_ACTIVE', 'G1').id;
    }

    get pasivoCorr() {
        return Util.abp.list.get('G_PASIVE', 'G1').id;
    }

    get pasivoNoCorr() {
        return Util.abp.list.get('G_PASIVE', 'G2').id;
    }

    get patrimonio() {
        return Util.abp.list.get('G_PASIVE', 'G3').id;
    }
    
    get utilidadOpera() {
        return Util.abp.list.get('G_GANANCIA', 'G3').id;
    }

    get ventaTotal() {
        return Util.abp.list.get('G_GANANCIA', 'G1').id;
    }

    get utilidad() {
        return Util.abp.list.get('G_GANANCIA', 'G2').id;
    }
                 
    get utilidadAntes() {
        return Util.abp.list.get('G_GANANCIA', 'G4').id;
    }

    get utilidadPerdi() {
        return Util.abp.list.get('G_GANANCIA', 'G5').id;
    }

    /***** fields events *****/
    
    getInitials(string) {
        return Util.getInitials(string);
    }

    onFocus(e) {
        e.target.select();
    }


    async saveAmount(item) {
        if (item != null) {
            await this.$store.dispatch({
                type: 'financialstatement/updateConcept',
                data: item
            });
        }
    }

    async mounted() {

    }

    async created() {
    }
}
